export const clientNameFooter = "SEXPLOR";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '0.63'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Sexplor | Sexual Wellness";
export const nomeFornecedor = "Sexplor";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/sexplor.oficial/",
    profile: "@sexplor.oficial"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/5554999172909",
    number: "(54) 99917-2909"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/5554999172909",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [];
